<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5 bgColor6">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid class="left-image" :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="6" class="d-flex align-items-center px-2 p-lg-5">
        <b-col sm="8" md="6" lg="7" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            FORGOT PASSWORD?
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions to reset your password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form class="auth-form mt-2" @submit.prevent="validationForm">
              <b-form-group label="Email" label-for="forgot-password-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input id="forgot-password-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                    name="forgot-password-email" placeholder="Enter email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button v-if="loading" type="submit" variant="primary" block class="mt-3" disabled>
                <b-spinner v-if="loading" label="Loading..." />
              </b-button>
              <b-button v-else type="submit" variant="primary" block class="mt-3">
                SEND VERIFICATION CODE
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-3">
            <b-link :to="{ name: 'auth-login-v2' }">
              <feather-icon icon="ArrowLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BSpinner,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      loading: false,
    }
  },
  computed: {
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
      //   return this.sideImg
      // }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true;
          const data = {
            email: this.userEmail,
          }
          axiosIns
            .post(`/send-verification-code`, data)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              setTimeout(() => {
                this.$router.replace('/')
              }, 2000);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
